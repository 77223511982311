import React, { useState, useEffect } from 'react';

import * as AdaptiveCards from "adaptivecards";
import * as markdownit from "markdown-it";
export default function ContextCard(props) {
    if (props.card === {}) {
        console.log(props.card, "empty")
    }
    console.log("Adding Card")
    let adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.parse(props.card);
    adaptiveCard.onExecuteAction = function (action) { console.log(action.data); }
    AdaptiveCards.AdaptiveCard.onProcessMarkdown = (text, result) => {
        result.outputHtml = new markdownit().render(text);
        result.didProcess = true;
    }
    const result = adaptiveCard.render()
    return <div style={{ "padding-right": "2rem", "padding-left": "1rem", "padding-bottom": "2rem" }} ref={(n) => { if (n && !n.firstChild) { n.appendChild(result); } }} />
}
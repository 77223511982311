import React, { useState, useEffect } from 'react';
import { Widget, addResponseMessage, setQuickButtons, addUserMessage, toggleMsgLoader, renderCustomComponent } from 'react-chat-widget';
import * as AdaptiveCards from "adaptivecards";
import * as markdownit from "markdown-it";
import socketIOClient from "socket.io-client";
import 'react-chat-widget/lib/styles.css';

import AdaptiveCard from './components/AdaptiveCard';

require('dotenv').config();
let socket;

// const socket = (process.env.NODE_ENV === 'production') ?  socketIOClient(process.env.REACT_APP_API_URL_PROD, {path: "/braggi/api/socket.io",}) : 

console.log("Build Setting: ", process.env.REACT_APP_SELF_ENV)

if (process.env.REACT_APP_SELF_ENV === 'production') {
  socket = socketIOClient(process.env.REACT_APP_API_URL_PROD, {path: "/braggi/api/socket.io",})
  console.log("Socket URL: ", process.env.REACT_APP_API_URL_PROD, " in Prod")
}

else {
  socket = socketIOClient(process.env.REACT_APP_API_URL_DEV)
  console.log("Socket URL: ", process.env.REACT_APP_API_URL_DEV, " in Dev")
}
//const socket = socketIOClient(process.env.REACT_APP_API_URL, {path: "/braggi/api/socket.io",})

console.log(socket)
socket.on('connect', function () {
  console.log(socket.id);
});
socket.on("recieveMessage", function (data) {
  console.log("Message: ", data)
  data.message.map((msg) => {
    console.log(msg)
    addResponseMessage(msg)
  })

  if (JSON.stringify(data.cards) !== "{}") {
    console.log("Adding Card")
    renderCustomComponent(AdaptiveCard, {card: data.cards})
  }

  let prompts = []
  data.prompts.map((prompt) => {
    console.log(prompt)
    prompts.push({ "label": prompt, "value": prompt })
  })
  setQuickButtons(prompts)
  toggleMsgLoader()
})

function App() {
  useEffect(() => {
    //addResponseMessage('Welcome to this awesome chat!');
    socket.emit("initEvent", {
      "event": "reset",
      "userid": socket.id,
      "botname": process.env.REACT_APP_BOTNAME,
      "persona": "prosocial",
      "markdownSupported": true,
      "clientType": process.env.REACT_APP_CLIENT_NAME
    });
    toggleMsgLoader()
  }, []);

  const handleNewUserMessage = (newMessage) => {
    removeCard();
    toggleMsgLoader();

    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API

    socket.emit("forwardToAPIandSave", {
      "userid": socket.id,
      "message": newMessage,
      "botname": process.env.REACT_APP_BOTNAME,
      "persona": "prosocial",
      "markdownSupported": true,
      "clientType": process.env.REACT_APP_CLIENT_NAME
    });
  };

  const handleQuickButtonClicked = (val) => {
    removeCard();
    addUserMessage(val);
    console.log("Button Val: ", val);
    handleNewUserMessage(val);
  }

  const removeCard = () => {
    const elements = document.getElementsByClassName("ac-container ac-adaptiveCard");

    while (elements.length > 0) elements[0].remove();
  }

  return (
    <div className="App">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        handleQuickButtonClicked={handleQuickButtonClicked}
        title={process.env.REACT_APP_BOTNAME_DISPLAY}
        subtitle={process.env.REACT_APP_BOTDESCRIPTION}
      />
    </div>
  );
}

export default App;

var card = {
  "type": "AdaptiveCard",
  "body": [
    {
      "type": "Container",
      "style": "emphasis",
      "items": [
        {
          "type": "ColumnSet",
          "columns": [
            {
              "type": "Column",
              "items": [
                {
                  "type": "TextBlock",
                  "size": "Large",
                  "weight": "Bolder",
                  "text": "**EXPENSE APPROVAL**"
                }
              ],
              "width": "stretch"
            },
            {
              "type": "Column",
              "items": [
                {
                  "type": "Image",
                  "url": "https://adaptivecards.io/content/pending.png",
                  "height": "30px",
                  "altText": "Pending"
                }
              ],
              "width": "auto"
            }
          ]
        }
      ],
      "bleed": true
    },
    {
      "type": "Container",
      "items": [
        {
          "type": "ColumnSet",
          "columns": [
            {
              "type": "Column",
              "items": [
                {
                  "type": "TextBlock",
                  "size": "ExtraLarge",
                  "text": "Trip to UAE",
                  "wrap": true
                },
                {
                  "type": "TextBlock",
                  "spacing": "Small",
                  "size": "Small",
                  "weight": "Bolder",
                  "color": "Accent",
                  "text": "[ER-13052](https://adaptivecards.io)"
                }
              ],
              "width": "stretch"
            },
            {
              "type": "Column",
              "items": [
                {
                  "type": "ActionSet",
                  "actions": [
                    {
                      "type": "Action.OpenUrl",
                      "title": "EXPORT AS PDF",
                      "url": "https://adaptivecards.io"
                    }
                  ]
                }
              ],
              "width": "auto"
            }
          ]
        },
        {
          "type": "FactSet",
          "spacing": "Large",
          "facts": [
            {
              "title": "Submitted By",
              "value": "**Matt Hidinger**  matt@contoso.com"
            },
            {
              "title": "Duration",
              "value": "2019/06/19 - 2019/06/25"
            },
            {
              "title": "Submitted On",
              "value": "2019/04/14"
            },
            {
              "title": "Reimbursable Amount",
              "value": "$ 404.30"
            },
            {
              "title": "Awaiting approval from",
              "value": "**Thomas**  thomas@contoso.com"
            },
            {
              "title": "Submitted to",
              "value": "**David**   david@contoso.com"
            }
          ]
        }
      ]
    },
    {
      "type": "Container",
      "spacing": "Large",
      "style": "emphasis",
      "items": [
        {
          "type": "ColumnSet",
          "columns": [
            {
              "type": "Column",
              "items": [
                {
                  "type": "TextBlock",
                  "weight": "Bolder",
                  "text": "DATE"
                }
              ],
              "width": "auto"
            },
            {
              "type": "Column",
              "spacing": "Large",
              "items": [
                {
                  "type": "TextBlock",
                  "weight": "Bolder",
                  "text": "CATEGORY"
                }
              ],
              "width": "stretch"
            },
            {
              "type": "Column",
              "items": [
                {
                  "type": "TextBlock",
                  "weight": "Bolder",
                  "text": "AMOUNT"
                }
              ],
              "width": "auto"
            }
          ]
        }
      ],
      "bleed": true
    },
    {
      "type": "ColumnSet",
      "columns": [
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "text": "06/19",
              "wrap": true
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "spacing": "Medium",
          "items": [
            {
              "type": "TextBlock",
              "text": "Air Travel Expense",
              "wrap": true
            }
          ],
          "width": "stretch"
        },
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "text": "$ 300",
              "wrap": true
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "id": "chevronDown1",
          "spacing": "Small",
          "verticalContentAlignment": "Center",
          "items": [
            {
              "type": "Image",
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "collapse",
                "targetElements": [
                  "cardContent1",
                  "chevronUp1",
                  "chevronDown1"
                ]
              },
              "url": "https://adaptivecards.io/content/down.png",
              "width": "20px",
              "altText": "collapsed"
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "id": "chevronUp1",
          "isVisible": false,
          "spacing": "Small",
          "verticalContentAlignment": "Center",
          "items": [
            {
              "type": "Image",
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "expand",
                "targetElements": [
                  "cardContent1",
                  "chevronUp1",
                  "chevronDown1"
                ]
              },
              "url": "https://adaptivecards.io/content/up.png",
              "width": "20px",
              "altText": "expanded"
            }
          ],
          "width": "auto"
        }
      ]
    },
    {
      "type": "Container",
      "id": "cardContent1",
      "isVisible": false,
      "items": [
        {
          "type": "Container",
          "items": [
            {
              "type": "TextBlock",
              "text": "* Leg 1 on Tue, Jun 19th, 2019 at 6:00 AM.",
              "isSubtle": true,
              "wrap": true
            },
            {
              "type": "TextBlock",
              "text": "* Leg 2 on Tue,Jun 19th, 2019 at 7:15 PM.",
              "isSubtle": true,
              "wrap": true
            },
            {
              "type": "Container",
              "items": [
                {
                  "type": "Input.Text",
                  "id": "comment1",
                  "placeholder": "Add your comment here."
                }
              ]
            }
          ]
        },
        {
          "type": "Container",
          "items": [
            {
              "type": "ColumnSet",
              "columns": [
                {
                  "type": "Column",
                  "items": [
                    {
                      "type": "ActionSet",
                      "actions": [
                        {
                          "type": "Action.Submit",
                          "title": "Send",
                          "data": {
                            "id": "_qkQW8dJlUeLVi7ZMEzYVw",
                            "action": "comment",
                            "lineItem": 1
                          }
                        }
                      ]
                    }
                  ],
                  "width": "auto"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "type": "ColumnSet",
      "columns": [
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "horizontalAlignment": "Center",
              "text": "06/19",
              "wrap": true
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "spacing": "Medium",
          "items": [
            {
              "type": "TextBlock",
              "text": "Auto Mobile Expense",
              "wrap": true
            }
          ],
          "width": "stretch"
        },
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "text": "$ 100",
              "wrap": true
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "id": "chevronDown2",
          "spacing": "Small",
          "verticalContentAlignment": "Center",
          "items": [
            {
              "type": "Image",
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "collapse",
                "targetElements": [
                  "cardContent2",
                  "chevronUp2",
                  "chevronDown2"
                ]
              },
              "url": "https://adaptivecards.io/content/down.png",
              "width": "20px",
              "altText": "collapsed"
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "id": "chevronUp2",
          "isVisible": false,
          "spacing": "Small",
          "verticalContentAlignment": "Center",
          "items": [
            {
              "type": "Image",
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "expand",
                "targetElements": [
                  "cardContent2",
                  "chevronUp2",
                  "chevronDown2"
                ]
              },
              "url": "https://adaptivecards.io/content/up.png",
              "width": "20px",
              "altText": "expanded"
            }
          ],
          "width": "auto"
        }
      ]
    },
    {
      "type": "Container",
      "id": "cardContent2",
      "isVisible": false,
      "items": [
        {
          "type": "Container",
          "items": [
            {
              "type": "TextBlock",
              "text": "* Contoso Car Rentrals, Tues 6/19 at 7:00 AM",
              "isSubtle": true,
              "wrap": true
            },
            {
              "type": "Container",
              "items": [
                {
                  "type": "Input.Text",
                  "id": "comment2",
                  "placeholder": "Add your comment here."
                }
              ]
            }
          ]
        },
        {
          "type": "Container",
          "items": [
            {
              "type": "ColumnSet",
              "columns": [
                {
                  "type": "Column",
                  "items": [
                    {
                      "type": "ActionSet",
                      "actions": [
                        {
                          "type": "Action.Submit",
                          "title": "Send",
                          "data": {
                            "id": "_qkQW8dJlUeLVi7ZMEzYVw",
                            "action": "comment",
                            "lineItem": 2
                          }
                        }
                      ]
                    }
                  ],
                  "width": "auto"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "type": "ColumnSet",
      "columns": [
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "horizontalAlignment": "Center",
              "text": "06/25",
              "wrap": true
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "spacing": "Medium",
          "items": [
            {
              "type": "TextBlock",
              "text": "Air Travel Expense",
              "wrap": true
            }
          ],
          "width": "stretch"
        },
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "text": "$ 4.30",
              "wrap": true
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "id": "chevronDown3",
          "spacing": "Small",
          "verticalContentAlignment": "Center",
          "items": [
            {
              "type": "Image",
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "collapse",
                "targetElements": [
                  "cardContent3",
                  "chevronUp3",
                  "chevronDown3"
                ]
              },
              "url": "https://adaptivecards.io/content/down.png",
              "width": "20px",
              "altText": "collapsed"
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "id": "chevronUp3",
          "isVisible": false,
          "spacing": "Small",
          "verticalContentAlignment": "Center",
          "items": [
            {
              "type": "Image",
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "expand",
                "targetElements": [
                  "cardContent3",
                  "chevronUp3",
                  "chevronDown3"
                ]
              },
              "url": "https://adaptivecards.io/content/up.png",
              "width": "20px",
              "altText": "expanded"
            }
          ],
          "width": "auto"
        }
      ]
    },
    {
      "type": "Container",
      "id": "cardContent3",
      "isVisible": false,
      "items": [
        {
          "type": "Container",
          "items": [
            {
              "type": "Input.Text",
              "id": "comment3",
              "placeholder": "Add your comment here."
            }
          ]
        },
        {
          "type": "Container",
          "items": [
            {
              "type": "ColumnSet",
              "columns": [
                {
                  "type": "Column",
                  "items": [
                    {
                      "type": "ActionSet",
                      "actions": [
                        {
                          "type": "Action.Submit",
                          "title": "Send",
                          "data": {
                            "id": "_qkQW8dJlUeLVi7ZMEzYVw",
                            "action": "comment",
                            "lineItem": 3
                          }
                        }
                      ]
                    }
                  ],
                  "width": "auto"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "type": "ColumnSet",
      "spacing": "Large",
      "separator": true,
      "columns": [
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "horizontalAlignment": "Right",
              "text": "Total Expense Amount \t",
              "wrap": true
            },
            {
              "type": "TextBlock",
              "horizontalAlignment": "Right",
              "text": "Non-reimbursable Amount",
              "wrap": true
            },
            {
              "type": "TextBlock",
              "horizontalAlignment": "Right",
              "text": "Advance Amount",
              "wrap": true
            }
          ],
          "width": "stretch"
        },
        {
          "type": "Column",
          "items": [
            {
              "type": "TextBlock",
              "text": "404.30"
            },
            {
              "type": "TextBlock",
              "text": "(-) 0.00 \t"
            },
            {
              "type": "TextBlock",
              "text": "(-) 0.00 \t"
            }
          ],
          "width": "auto"
        },
        {
          "type": "Column",
          "width": "auto"
        }
      ]
    },
    {
      "type": "Container",
      "style": "emphasis",
      "items": [
        {
          "type": "ColumnSet",
          "columns": [
            {
              "type": "Column",
              "items": [
                {
                  "type": "TextBlock",
                  "horizontalAlignment": "Right",
                  "text": "Amount to be Reimbursed",
                  "wrap": true
                }
              ],
              "width": "stretch"
            },
            {
              "type": "Column",
              "items": [
                {
                  "type": "TextBlock",
                  "weight": "Bolder",
                  "text": "$ 404.30"
                }
              ],
              "width": "auto"
            },
            {
              "type": "Column",
              "width": "auto"
            }
          ]
        }
      ],
      "bleed": true
    },
    {
      "type": "Container",
      "items": [
        {
          "type": "ColumnSet",
          "columns": [
            {
              "type": "Column",
              "id": "chevronDown4",
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "show history",
                "targetElements": [
                  "cardContent4",
                  "chevronUp4",
                  "chevronDown4"
                ]
              },
              "verticalContentAlignment": "Center",
              "items": [
                {
                  "type": "TextBlock",
                  "horizontalAlignment": "Right",
                  "color": "Accent",
                  "text": "Show history",
                  "wrap": true
                }
              ],
              "width": 1
            },
            {
              "type": "Column",
              "id": "chevronUp4",
              "isVisible": false,
              "selectAction": {
                "type": "Action.ToggleVisibility",
                "title": "hide history",
                "targetElements": [
                  "cardContent4",
                  "chevronUp4",
                  "chevronDown4"
                ]
              },
              "verticalContentAlignment": "Center",
              "items": [
                {
                  "type": "TextBlock",
                  "horizontalAlignment": "Right",
                  "color": "Accent",
                  "text": "Hide history",
                  "wrap": true
                }
              ],
              "width": 1
            }
          ]
        }
      ]
    },
    {
      "type": "Container",
      "id": "cardContent4",
      "isVisible": false,
      "items": [
        {
          "type": "Container",
          "items": [
            {
              "type": "TextBlock",
              "text": "* Expense submitted by **Matt** on Wed, Apr 14th, 2019",
              "isSubtle": true,
              "wrap": true
            },
            {
              "type": "TextBlock",
              "text": "* Expense approved by **Thomas** on Thu, Apr 15th, 2019",
              "isSubtle": true,
              "wrap": true
            },
            {
              "type": "Input.Date",
              "id": "dueDate"
            },
          ]
        }
      ]
    },
    {
      "type": "Container",
      "items": [
        {
          "type": "ActionSet",
          "actions": [
            {
              "type": "Action.Submit",
              "title": "Approve",
              "style": "positive",
              "data": {
                "id": "_qkQW8dJlUeLVi7ZMEzYVw",
                "action": "approve"
              }
            },
            {
              "type": "Action.ShowCard",
              "title": "Reject",
              "style": "destructive",
              "card": {
                "type": "AdaptiveCard",
                "body": [
                  {
                    "type": "Input.Text",
                    "id": "RejectCommentID",
                    "placeholder": "Please specify an appropriate reason for rejection.",
                    "isMultiline": true
                  }
                ],
                "actions": [
                  {
                    "type": "Action.Submit",
                    "title": "Send",
                    "data": {
                      "id": "_qkQW8dJlUeLVi7ZMEzYVw",
                      "action": "reject"
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
  "version": "1.2",
  "fallbackText": "This card requires Adaptive Cards v1.2 support to be rendered properly."
}